import { useState } from 'react'
import Spinner from 'react-bootstrap/Spinner';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';

import Game from './components/game/Game'
import Players from './components/players/Players'
import Rounds from './components/rounds/Rounds'
import Results from './components/results/Results'

let socket = new WebSocket(process.env.REACT_APP_WEBSOCKET_URL);

function App() {

  const [connected, setConnected] = useState(false)
  const [game, setGame] = useState(null)
  const [loading, setLoading] = useState(true)

  socket.onopen = () => {
    console.log('Connected to WebSocket server');
    setConnected(true)
    setLoading(false)
  };

  socket.onmessage = (event) => {
    try {
      const fixed = event.data.replaceAll('\\"', '"').slice(1, -1)
      setGame(JSON.parse(fixed))
    } catch (err) {
      setGame(JSON.parse(event.data))
    }
  };

  socket.onclose = () => {
    setConnected(false)
    setGame(null)
  };

  return (
    <div>
      {loading && (
        <Container>
          <Row className="justify-content-center align-items-center">
            <Spinner animation="border" variant="primary" />
          </Row>
        </Container>
      )}
      {connected && (
        <Row className="px-1">
          <Col>
            <Tabs
              defaultActiveKey="game"
              id="uncontrolled-tab-example"
              className="mb-3 justify-content-center"
            >
              <Tab eventKey="game" title="Peli">
                <Game game={game} socket={socket} />
              </Tab>
              <Tab eventKey="players" title="Pelaajat" disabled={!game}>
                <Players game={game} socket={socket} />
              </Tab>
              <Tab eventKey="rounds" title="Kierrokset" disabled={!game || game?.players?.length === 0}>
                <Rounds game={game} socket={socket} />
              </Tab>
              <Tab eventKey="result" title="Tulokset" disabled={!game || game?.players?.length === 0 ||  game?.rounds?.length === 0 }>
                <Results game={game} />
              </Tab>
            </Tabs>
          </Col>
        </Row>
      )}
    </div>
  );
}

export default App;
