import { useEffect, useState } from 'react'
import dayjs from 'dayjs'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Spinner from 'react-bootstrap/Spinner';
import Toast from 'react-bootstrap/Toast';

const MILLISECONDS_IN_MINUTE = 60 * 1000
const MINUTES = 60
const VALID_TIME = MINUTES * MILLISECONDS_IN_MINUTE

export default function Game({ game, socket }) {

    const [gameName, setGameName] = useState('')
    const [loading, setLoading] = useState(false)
    const [showToast, setShowToast] = useState(false)

    useEffect(() => {
        const valid = localStorage.getItem("gameValid")
        const name = localStorage.getItem("gameName")
        const now = dayjs().valueOf()
        if (name && valid && Number(valid) > now) {
          setGameName(name)
          handleClick(name)
        }
      }, [])

    useEffect(() => {
        if (loading) {
            setLoading(false)
            setShowToast(true)
        }
    }, [game])

    const handleClick = async (name = '') => {
        if (name !== '' || gameName !== '') {
            setLoading(true)
            const now = dayjs().valueOf()
            const valid = now + VALID_TIME
            localStorage.setItem("gameValid", valid)
            localStorage.setItem("gameName", gameName !== '' ? gameName : name)
            socket.send(JSON.stringify({"action": "updategame", "game": gameName !== '' ? gameName : name}))
        }
    }

    return (
        <Container>
            <Row>
                <Col>
                    <Form.Control
                        type="text"
                        placeholder="Pelin nimi"
                        value={gameName}
                        onChange={(e) => setGameName(e.target.value)}
                        disabled={loading}
                    />
                </Col>
            </Row>
            {!loading && (
            <Row className="my-2">
                <Col>
                    <Button variant="primary" disabled={gameName === '' || loading || gameName === game?.name} onClick={() => handleClick()}>Liity</Button>
                </Col>
            </Row>)}
            {loading && (
            <Row className="justify-content-center align-items-center my-2">
                <Spinner animation="border" variant="primary" />
            </Row>
            )}
            <Toast onClose={() => setShowToast(false)} show={showToast} delay={3000} autohide>
                <Toast.Body>{`Liityit peliin: ${game?.name}`}</Toast.Body>
            </Toast>
        </Container>
    )
}