import { useEffect, useState } from 'react'
import Table from 'react-bootstrap/Table';

export default function Results({ game }) {
    const [results, setResults] = useState([])

    useEffect(() => {
        const res = game?.players?.map((player, i) => {
            const points = game.rounds.map((r) => r[i])
            let total = 0
            points.forEach((p) => total += p)
            return {
                name: player,
                points,
                total
            }
        })
        res?.sort((a, b) => {
            return b.total - a.total
        })
        setResults(res)
    }, [game])

    return (
        <Table striped bordered hover responsive>
            <thead>
                <tr>
                    <th></th>
                    {game?.rounds?.map((r, i) => <th key={i} style={{ textAlign: "center" }}>{i + 1}</th>)}
                    <th style={{ textAlign: "center" }}>Yhteensä</th>
                </tr>
            </thead>
            <tbody>
                {results?.map((res, i) => (
                    <tr key={i}>
                        <th>{res.name}</th>
                        {res?.points?.map((p, i) => (
                            <td key={i} style={{ textAlign: "center" }}>{p}</td>
                        ))}
                        <td style={{ textAlign: "center" }}>{res.total}</td>
                    </tr>
                ))}
            </tbody>
        </Table>
    )
}