import { useEffect, useState } from 'react'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Spinner from 'react-bootstrap/Spinner';
import Toast from 'react-bootstrap/Toast';

export default function Players({ game, socket }) {

    const [names, setNames] = useState(['', '', '', ''])
    const [loading, setLoading] = useState(false)
    const [showToast, setShowToast] = useState(false)

    useEffect(() => {
        if (loading) {
            setLoading(false)
            setShowToast(true)
        }
        if (game?.players?.length > 0) {
            setNames(game.players)
        }
    }, [game])

    const handleChange = (index, value) => {
        const updated = [...names]
        updated.splice(index, 1, value)
        setNames(updated)
    }
    const handleClick = async () => {
        setLoading(true)
        const updatedGame = { ...game, players: names }
        socket.send(JSON.stringify({"action": "sendmessage", "message": updatedGame }))
    }

    const playerIndexes = [0, 1, 2, 3]

    return (
        <Container>
            {playerIndexes.map((i) => (
            <Row className="py-2" key={i}>
                <Col>
                    <Form.Control
                        type="text"
                        placeholder="Pelaajan nimi"
                        value={names[i]}
                        onChange={(e) => handleChange(i, e.target.value)}
                        disabled={loading}
                    />
                </Col>
            </Row>))}
            {!loading && (
            <Row className="my-2">
                <Col>
                    <Button
                        variant="primary"
                        disabled={
                            names.some((name) => name === '') ||
                            names.every((name, i) => name === game?.players[i])
                        }
                        onClick={() => handleClick()}
                    >Tallenna</Button>
                </Col>
            </Row>)}
            {loading && (
            <Row className="justify-content-center align-items-center my-2">
                <Spinner animation="border" variant="primary" />
            </Row>
            )}
            <Toast onClose={() => setShowToast(false)} show={showToast} delay={3000} autohide>
                <Toast.Body>Pelaajat tallennettu</Toast.Body>
            </Toast>
        </Container>
    )
}