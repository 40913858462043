import { useEffect, useState } from 'react'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Spinner from 'react-bootstrap/Spinner';
import Toast from 'react-bootstrap/Toast';
import ListGroup from 'react-bootstrap/ListGroup';
import InputGroup from 'react-bootstrap/InputGroup';

const sameValues = (a, b) => {
    return a.every((v, i) => v === b[i])
}

function ListItem({ index, rounds, selectedRound, setSelectedRound, game, setRounds, socket, setLoading }) {

    const round = rounds[index]

    const handleClickChange = (i, value) => {
        const updatedRound = [...round]
        updatedRound.splice(i, 1, value)

        const updated = [...rounds]
        updated.splice(index, 1, updatedRound)

        setRounds(updated)
    }

    const handleChange = (i, value) => {
        if (value >= 0 && value <= 16) {
            const updatedRound = [...round]
            updatedRound.splice(i, 1, Number(value))
    
            const updated = [...rounds]
            updated.splice(index, 1, updatedRound)
    
            setRounds(updated)
        }
    }

    const handleSave = async () => {
        const updatedGame = { ...game, rounds: rounds }
        setLoading(true)
        socket.send(JSON.stringify({"action": "sendmessage", "message": updatedGame }))
    }

    const handleRemove = async () => {
        const updated = [...rounds]
        updated.splice(index, 1)

        setRounds(updated)
        const updatedGame = { ...game, rounds: updated }
        setLoading(true)
        socket.send(JSON.stringify({"action": "sendmessage", "message": updatedGame }))
    }

    const open = selectedRound === index

    const hasChanges = !game?.rounds[index] || !sameValues(round, game.rounds[index])

    return (
    <ListGroup.Item
        as="li"
        style={open ? { 'border': '2px solid #0d6efd' } : { 'borderWidth': '1px'}}
        className={open ? 'my-1' : ''}
        onClick={() => setSelectedRound(index)}
    >
        Kierros: {index + 1}
        {open && (
            <Container className="my-4">
                {game?.players.map((p, i) => (
                <Row className="my-1" key={i}>
                    <Col xs={3} className="ps-0">
                        {p}
                    </Col>
                    <Col>
                        <InputGroup className="mb-3">
                            <Button variant="outline-secondary" id="button-addon1"
                                onClick={() => handleClickChange(i, Math.max(round[i] - 1, 0))}
                            >
                                -
                            </Button>
                            <Form.Control
                                className="text-center"
                                aria-label="Example text with button addon"
                                aria-describedby="basic-addon1"
                                inputMode='numeric'
                                value={round[i]}
                                onChange={(e) => handleChange(i, e.target.value)}
                            />
                            <Button variant="outline-secondary" id="button-addon2"
                                onClick={() => handleClickChange(i, Math.min(round[i] + 1, 16))}
                            >
                                +
                            </Button>
                        </InputGroup>
                    </Col>
                </Row>
                ))}
                {hasChanges && (
                <Row className="my-2">
                    <Col xs={8}>
                        <Button
                            variant="primary"
                            onClick={() => handleSave()}
                        >Tallenna</Button>
                    </Col>
                    <Col>
                        <Button
                            variant="danger"
                            onClick={() => handleRemove()}
                        >Poista</Button>
                    </Col>
                </Row>
                )}
            </Container>
        )}
    </ListGroup.Item>
    )
}

export default function Rounds({ game, socket }) {

    const [rounds, setRounds] = useState(game?.rounds ?? [])
    const [loading, setLoading] = useState(false)
    const [showToast, setShowToast] = useState(false)
    const [selectedRound, setSelectedRound] = useState(0)
    const [hasChanges, setHasChanges] = useState(false)

    useEffect(() => {
        const changes = rounds.some((r, i) => {
            if (!game?.rounds[i]) {
                return true
            } else if (!sameValues(r, game.rounds[i])) {
                return true
            }
            return false
        })
        setHasChanges(changes)
    }, [rounds, game])
    

    useEffect(() => {
        if (loading) {
            setLoading(false)
            setShowToast(true)
        }
        if (game?.rounds && !hasChanges) {
            setRounds(game.rounds)
            if (selectedRound === 0) {
                setSelectedRound(game.rounds.length - 1)
            }
        }
    }, [game])

    const addRound = async () => {
        const updated = rounds.concat([[8, 8, 8, 8]])
        setRounds(updated)
        setSelectedRound(rounds.length - 1)
    }

    return (
        <Container>
            <ListGroup as="ul">
                {rounds.map((round, index) => {
                    return (
                        <ListItem
                            rounds={rounds}
                            key={index}
                            index={index}
                            selectedRound={selectedRound}
                            setSelectedRound={setSelectedRound}
                            game={game}
                            setRounds={setRounds}
                            socket={socket}
                            setLoading={setLoading}
                        />
                )})}
            </ListGroup>
            {!loading && (
            <Row className="my-2">
                <Col>
                    <Button
                        variant="primary"
                        disabled={hasChanges}
                        onClick={() => addRound()}
                    >Uusi kierros</Button>
                </Col>
            </Row>)}
            {loading && (
            <Row className="justify-content-center align-items-center my-2">
                <Spinner animation="border" variant="primary" />
            </Row>
            )}
            <Toast onClose={() => setShowToast(false)} show={showToast} delay={3000} autohide position="middle-center">
                <Toast.Body>Muutokset tallennettu</Toast.Body>
            </Toast>
        </Container>
    )
}